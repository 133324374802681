var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statistics" }, [
    _vm.isLogo
      ? _c("div", { staticClass: "logotype" }, [_vm._t("logo")], 2)
      : _vm._e(),
    _vm.isTitle
      ? _c(
          "div",
          {
            staticClass: "statistics-left",
            style: {
              minWidth:
                (_vm.titleWidth ? _vm.titleWidth : _vm.title.length * 20 + 60) +
                "px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.title
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ])
                  : _vm._t("title"),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "statistics-center" },
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "statistics-center-item" },
          [
            _c(
              "div",
              {
                staticClass: "content",
                style: {
                  width:
                    (item.label.length + (item.unit ? item.unit.length : 0)) *
                      20 +
                    "px",
                },
              },
              [
                item.isMoney == true
                  ? _c(
                      "div",
                      {
                        staticClass: "figures",
                        style: { textAlign: _vm.textAlign },
                      },
                      [
                        item.num == 0 ||
                        item.num == null ||
                        item.num == undefined
                          ? _c("span", [_vm._v("--")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("stateFormatNum")(item.num))
                              ),
                            ]),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "figures",
                        style: { textAlign: _vm.textAlign },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(item.num === 0 ? "--" : item.num) + " "
                        ),
                      ]
                    ),
                _c(
                  "div",
                  { staticClass: "label", style: { textAlign: _vm.textAlign } },
                  [
                    _vm._v(
                      _vm._s(item.label ? item.label : "") +
                        _vm._s(item.unit ? "(" + item.unit + ")" : "")
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm.isFunction
      ? _c(
          "div",
          {
            staticClass: "statistics-right",
            style: { minWidth: _vm.minWidth + "px" },
          },
          [_c("div", { staticClass: "content" }, [_vm._t("right")], 2)]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }